import { createRouter, createWebHistory } from 'vue-router';
import AuthService from '../services/auth.service';
import Home from '../views/index.vue';
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'Ads Manager',
        component: () => import('../views/components/tabs.vue'),
        meta: { layout: 'app', requiresAuth: true }
    },
    {
        path: '/settings/credentials',
        name: 'credentials',
        component: () => import('../views/pages/sample.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/settings/generateUrl',
        name: 'generateUrl',
        component: () => import('../views/pages/generateUrl.vue'),
        meta: { requiresAuth: true }
    },
    // Assuming /auth/login is now /auth/signin for consistency
    {
        path: '/auth/signin',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth', public: true },
    },
    {
        path: '/auth/register',
        name: 'register',
        component: () => import('../views/auth/register.vue'),
        meta: { layout: 'auth', public: true },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const isAuthenticated = await AuthService.isAuthenticated();

    if (!isPublic && !isAuthenticated) {
        next({ path: '/auth/signin' });
    } else {
        if (to.meta && to.meta.layout) {
            store.commit('setLayout', to.meta.layout === 'auth' ? 'auth' : 'app');
        }
        next();
    }
});

export default router;
