import axios from 'axios';

const API_URL = 'https://api.skopro.com/api/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password,
      token: user.token
    });
  }
  
  async isAuthenticated() {
        try {
            // Assuming 'user' is stored in localStorage and contains the token
            const user = JSON.parse(localStorage.getItem('user'));
            const token = user?.accessToken; // Make sure to adjust based on how you're storing the token

            if (!token) return false;

            // Adapt this endpoint to match your server's token validation endpoint
            const response = await axios.post(API_URL + 'token/validate', { token });

            // The server should return a JSON response indicating if the token is valid
            return response.data.valid;
        } catch (error) {
            console.error('Token validation error:', error);
            return false;
        }
    }
}

export default new AuthService();
